import React from "react";
import BaseLayout from "../components/baseLayout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HeroTitle from "../components/heroTitle";

export default function TeamMember({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <BaseLayout>
      <HeroTitle
        title={frontmatter.name}
        subtitle={frontmatter.jobDescription}
      />
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-4-tablet">
              <GatsbyImage
                image={frontmatter.img.childImageSharp.gatsbyImageData}
                alt={frontmatter.name}
              />
            </div>
            <div className="column content has-text-justified">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        name
        jobDescription
        img {
          childImageSharp {
            gatsbyImageData(width: 300, height: 300)
          }
        }
      }
    }
  }
`;


